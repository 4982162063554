import img1 from './img/img1.jpg'
import img2 from './img/img2.jpg'
import img3 from './img/img3.jpg'
import img4 from './img/img4.jpg'
import img5 from './img/img5.jpg'
import img6 from './img/img6.jpg'
import img7 from './img/img7.jpg'
import img8 from './img/img8.jpg'
import img9 from './img/img9.JPG'
import img10 from './img/img10.jpg'
import img11 from './img/img11.JPG'
import img12 from './img/img12.jpg'
import img13 from './img/Creative-Landscapes-Finalists.webp'
import img14 from './img/img14.jpg'
import img15 from './img/img15.JPG'
import img16 from './img/img16.jpg'
import img17 from './img/img17.jpg'


import img20 from './img/force-pas-avec-les-noms.webp'
import img21 from './img/2.webp'

// rememt ton 3;img dans les images
import img22 from './img/golf.png'

import img23 from './img/billard.png'

import img24 from './img/space.png'

import img25 from './img/duo2.png'
import img26 from './img/7.webp'
import img27 from './img/2.webp'








export default {
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,

    img20,
    img21,
    img22,
    img23,
    img24,
    img25,
    img26,
    img27,


    
};
